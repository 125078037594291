import { gsap } from "gsap";

const comparisonSlider = document.querySelector(".comparison-slider input");
const afterImage = document.querySelector(".image-container .image-after");
const dragLine = document.querySelector(".comparison-slider .drag-line");

if (comparisonSlider) {
  comparisonSlider.value = 100;

  gsap.to(comparisonSlider, {
    value: 0,
    duration: 3.3,
    ease: "power1.inOut",
    repeat: -1,
    yoyo: true,
    onUpdate: () => {
      const sliderValue = comparisonSlider.value;
      dragLine.style.left = sliderValue + "%";
      afterImage.style.clipPath = `inset(0 ${100 - sliderValue}% 0 0)`;
    },
    delay: 1
  });
}
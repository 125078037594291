document.addEventListener('turbo:load', function () {
	function initTabs(tabsContainer) {
		const tabsParent = tabsContainer;
		const tabs = tabsParent.querySelectorAll(".tab");
		const parentLeftDistance = tabsParent.getBoundingClientRect().left;

		function updateActiveStyles(element) {
			const elementSize = element.clientWidth;
			const elementLeftDistance = element.getBoundingClientRect().left;
			const distanceFromParent = elementLeftDistance - parentLeftDistance;
			tabsParent.style.setProperty("--indicator-offset", `${distanceFromParent}px`);
			tabsParent.style.setProperty("--indicator-width", `${elementSize}px`);
		}

		function showContent(activeTab) {
			const allContents = tabsParent.parentNode.querySelectorAll(`.${tabsParent.dataset.tabGroup}-content`);
			allContents.forEach(content => content.classList.remove('active'));

			const activeContentClass = activeTab.getAttribute('data-target');
			const activeContent = tabsParent.parentNode.querySelector(`.${activeContentClass}`);
			if (activeContent) {
				activeContent.classList.add('active');
			}

			const section = tabsParent.closest('.container-wrap');
			const allImages = section.querySelectorAll('.tabs-bg-image');
			allImages.forEach(image => image.classList.remove('active'));

			const activeImage = section.querySelector(`.tabs-bg-image.${activeContentClass}`);
			if (activeImage) {
				activeImage.classList.add('active');
			}

			const allSubtitles = section.querySelectorAll('.why-subtitle');
			allSubtitles.forEach(subtitle => subtitle.classList.remove('active'));

			const activeSubtitle = section.querySelector(`.why-subtitle.${activeContentClass}`);
			if (activeSubtitle) {
				activeSubtitle.classList.add('active');
			}
		}

		tabs.forEach((tab) => {
			tab.addEventListener('mouseenter', function () {
				tabs.forEach((t) => t.classList.remove("active"));
				this.classList.add("active");
				updateActiveStyles(this);
				showContent(this);
			});
		});

		// Set the first tab as active on page load
		if (tabs.length > 0) {
			tabs.forEach((tab) => tab.classList.remove("active"));
			tabs[0].classList.add("active");
			updateActiveStyles(tabs[0]);
			showContent(tabs[0]);
		}
	}

	const allTabContainers = document.querySelectorAll('.tabs[data-tab-group]');
	allTabContainers.forEach(initTabs);
});



//===========ADAPTIVE TABS================

document.addEventListener('turbo:load', function () {
	function initTabs(tabsContainer) {
		const tabsParent = tabsContainer;
		const tabs = tabsParent.querySelectorAll(".tab");

		function showContent(activeContent) {
			const section = tabsParent.closest('.container-wrap');
			const allContents = section.querySelectorAll(`.${tabsParent.dataset.tabGroup}-content`);
			allContents.forEach(content => content.classList.remove('active'));

			activeContent.forEach(content => content.classList.add('active'));
		}

		function showImages(activeContentClass) {
			const section = tabsParent.closest('.container-wrap');
			const allImages = section.querySelectorAll('.tabs-bg-image');
			allImages.forEach(image => image.classList.remove('active'));

			const activeImage = section.querySelector(`.tabs-bg-image.${activeContentClass}`);
			if (activeImage) {
				activeImage.classList.add('active');
			}

			const allSubtitles = section.querySelectorAll('.why-subtitle');
			allSubtitles.forEach(subtitle => subtitle.classList.remove('active'));

			const activeSubtitle = section.querySelector(`.why-subtitle.${activeContentClass}`);
			if (activeSubtitle) {
				activeSubtitle.classList.add('active');
			}
		}

		tabs.forEach((tab) => {
			tab.addEventListener('mouseover', function () {
				const section = tabsParent.closest('.container-wrap');
				const tabsInSection = section.querySelectorAll('.tab');
				tabsInSection.forEach((t) => t.classList.remove("active"));
				this.classList.add("active");

				const activeContentClass = this.getAttribute('data-target');
				const activeContent = section.querySelectorAll(`.${activeContentClass}`);
				if (activeContent.length > 0) {
					showContent(activeContent);
					showImages(activeContentClass);
				}
			});
		});

		// Set the first tab as active on page load
		if (tabs.length > 0) {
			tabs[0].classList.add("active");
			const defaultActiveContentClass = tabs[0].getAttribute('data-target');
			const section = tabsParent.closest('.container-wrap');
			const defaultActiveContent = section.querySelectorAll(`.${defaultActiveContentClass}`);
			if (defaultActiveContent.length > 0) {
				showContent(defaultActiveContent);
				showImages(defaultActiveContentClass);
			}
		}
	}

	const allTabContainers = document.querySelectorAll('.tabs-mob[data-tab-group]');
	allTabContainers.forEach(initTabs);
});



//========tabs on realized projects==============

document.addEventListener('turbo:load', function () {
	const commerceTab = document.querySelector('.commerce-tab');
	const privateTab = document.querySelector('.private-tab');
	const commerceSlider = document.querySelector('.commerce-slider');
	const privateSlider = document.querySelector('.private-slider');
	
	if (commerceTab) {
		commerceTab.classList.add('active'); 
		commerceSlider.classList.add('active'); 
	
		commerceTab.addEventListener('click', function () {
			commerceTab.classList.add('active');
			privateTab.classList.remove('active');
			commerceSlider.classList.add('active');
			privateSlider.classList.remove('active');
		});
	
		privateTab.addEventListener('click', function () {
			commerceTab.classList.remove('active');
			privateTab.classList.add('active');
			commerceSlider.classList.remove('active');
			privateSlider.classList.add('active');
		});
	}
});

//========tabs on product section (home page)==============

document.addEventListener("turbo:load", function () {
	const tabs = document.querySelectorAll('.product-tab');
	const contents = document.querySelectorAll('.products-content');

	tabs.forEach(tab => {
		tab.addEventListener('click', function () {
			tabs.forEach(tab => tab.classList.remove('active'));
			tab.classList.add('active');

			const target = tab.getAttribute('data-target');

			contents.forEach(content => content.classList.remove('active'));
			document.querySelector(`.${target}`).classList.add('active');
		});
	});
});



//========tabs on Documentation==============

document.addEventListener('turbo:load', function () {
	const techInfoTab = document.querySelector('.tech-info-tab');
	const certificatesTab = document.querySelector('.certificates-tab');
	const techInfoSlider = document.querySelector('.tech-info-slider');
	const certificatesSlider = document.querySelector('.certificates-slider');

	if (techInfoTab) {
		techInfoTab.classList.add('active');
		techInfoSlider.classList.add('active');

		techInfoTab.addEventListener('click', function () {
			techInfoTab.classList.add('active');
			certificatesTab.classList.remove('active');
			techInfoSlider.classList.add('active');
			certificatesSlider.classList.remove('active');
		});

		certificatesTab.addEventListener('click', function () {
			techInfoTab.classList.remove('active');
			certificatesTab.classList.add('active');
			techInfoSlider.classList.remove('active');
			certificatesSlider.classList.add('active');
		});
	}
});

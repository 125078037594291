document.addEventListener("turbo:load", function () {
  const videoElement = document.querySelector(".video.dark video");
  const appearElement = document.querySelector(".kayak h1 .appear");

  if (videoElement && appearElement) {
    function updateTextVisibility() {
      const currentTime = videoElement.currentTime;
      if (currentTime >= 3 && currentTime < 10) {
        appearElement.style.opacity = "1";
        appearElement.style.visibility = "visible";
      } else {
        appearElement.style.opacity = "0";
        appearElement.style.visibility = "hidden";
      }
    }

    videoElement.addEventListener('timeupdate', updateTextVisibility);
    videoElement.addEventListener('play', updateTextVisibility);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          videoElement.play();
          updateTextVisibility();
        } else {
          videoElement.pause();
          videoElement.currentTime = 0;
          appearElement.style.opacity = "0";
          appearElement.style.visibility = "hidden";
        }
      });
    });

    observer.observe(document.querySelector(".section-video.kayak"));
  }
});


document.addEventListener('turbo:load', () => {
  const videos = document.querySelectorAll('.from-beginning video');

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      const video = entry.target;

      if (entry.isIntersecting) {
        video.play();
      } else {
        video.pause();
        video.currentTime = 0;
      }
    });
  }, options);

  videos.forEach(video => {
    observer.observe(video);
  });
});






import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false



require("./packs/header")
require("./packs/slider")
require("./packs/tabs")
require("./packs/home_products")
require("./packs/comparison")
require("./packs/home")
require("./packs/anchors")




document.addEventListener("turbo:load", () => {
  const screenWidth = window.innerWidth;
  document.cookie = `screen_width=${screenWidth}; path=/`;
});
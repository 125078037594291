document.addEventListener('turbo:load', function () {
	let lastScrollTop = 0;
	const header = document.querySelector('.header');
	const logoImg = document.querySelector('.logo-img');
	const logoText = document.querySelector('.logo-text');
	if (header) {
		window.addEventListener('scroll', () => {
			const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
			if (currentScrollTop > lastScrollTop) {
				header.classList.remove('up');
				header.classList.add('down');
				header.classList.remove('open');
			} else {
				header.classList.remove('down');
				header.classList.add('up');
			}
			if (currentScrollTop < 50) {
				header.classList.add('up');
				header.classList.remove('active');
				logoImg.classList.remove('active');
				logoText.classList.remove('active');
			} else {
				header.classList.add('active');
				logoImg.classList.add('active');
				logoText.classList.add('active');
			}
			lastScrollTop = currentScrollTop;
		});
	}
});


//=========Lang menu===============

const currentBox = document.querySelector('.current-box');
const langMenu = document.querySelector('.lang-menu');
const innerBox = document.querySelector('.lang-menu .inner-box');
var body = document.querySelector('body');

if (currentBox) {
	currentBox.addEventListener('click', function (event) {
		const isOpen = langMenu.classList.toggle('open');
		currentBox.classList.toggle('open', isOpen);
		currentBox.classList.toggle('active', isOpen);
		body.style.overflow = isOpen ? 'hidden' : '';
		event.stopPropagation();
	});

	document.addEventListener('click', function (event) {
		if (!innerBox.contains(event.target) && event.target !== currentBox) {
			langMenu.classList.remove('open');
			currentBox.classList.remove('open');
			currentBox.classList.remove('active');
			body.style.overflow = '';
		}
	});
}

//=======Cooperation===============

const cooperationHeader = document.querySelector('.cooperation-header');
const cooperationBox = document.querySelector('.cooperation-header-box');
if (cooperationBox) {
	function addActiveClass() {
		cooperationBox.classList.add('active');
		cooperationHeader.classList.add('active');
	}

	function removeActiveClass() {
		cooperationBox.classList.remove('active');
		cooperationHeader.classList.remove('active');
	}

	cooperationHeader.addEventListener('mouseover', addActiveClass);
	cooperationBox.addEventListener('mouseover', addActiveClass);

	cooperationBox.addEventListener('mouseout', (event) => {
		if (!cooperationBox.contains(event.relatedTarget)) {
			removeActiveClass();
		}
	});

	cooperationHeader.addEventListener('mouseout', (event) => {
		if (!cooperationHeader.contains(event.relatedTarget) && !cooperationBox.contains(event.relatedTarget)) {
			removeActiveClass();
		}
	});
}


//=========Menu Burger================

document.addEventListener('turbo:load', function () {
	var openMenu = document.querySelector('.burger-btn');
	var menu = document.querySelector('.adaptive-menu');
	var body = document.querySelector('body');
	var menuLinks = menu.querySelectorAll('a');

	function toggleMenu() {
		menu.classList.toggle('active');
		openMenu.classList.toggle('active');
		if (menu.classList.contains('active')) {
			body.style.overflow = 'hidden';
		} else {
			body.style.overflow = '';
		}
	}

	openMenu.addEventListener('click', function (event) {
		toggleMenu();
		event.stopPropagation();
	});

	menu.addEventListener('click', function (event) {
		event.stopPropagation();
	});

	document.addEventListener('click', function (event) {
		var isClickInsideMenu = menu.contains(event.target);

		if (!isClickInsideMenu && menu.classList.contains('active')) {
			toggleMenu();
		}
	});

	menuLinks.forEach(function (link) {
		link.addEventListener('click', function () {
			toggleMenu();
		});
	});
});


//===========Cooperation Adaptive====================

document.addEventListener('turbo:load', function () {
	const cooperationParent = document.querySelector('.cooperation-parent');
	const cooperationChild = document.querySelector('.cooperation-child');

	cooperationParent.addEventListener('click', function (event) {
		event.stopPropagation(); 
		cooperationParent.classList.toggle('active');
		cooperationChild.classList.toggle('active');
	});

	document.addEventListener('click', function (event) {
		if (!cooperationParent.contains(event.target)) {
			cooperationParent.classList.remove('active');
			cooperationChild.classList.remove('active');
		}
	});
});


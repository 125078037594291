document.addEventListener('turbo:load', function () {
	const anchors = document.querySelectorAll('a[href="#products-categories"], a[href="#model"], a[href="#description"], a[href="#options"], a[href="#contact"], a[href="#design"], a[href="#drawings"], a[href="#colors"], a[href="#specification"]');

	for (let anchor of anchors) {
		anchor.addEventListener('click', function (e) {
			e.preventDefault();
			const blockID = anchor.getAttribute('href').substr(1);

			document.getElementById(blockID).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		});
	}
});